<template>
  <div ref="brandCardDiv" style="cursor: pointer;" class="brand-card">
    <v-hover v-slot:default="{ hover }">
      <v-card :elevation="hover ? 20 : 4" :class="staticClasses" :style="borderStyle.border">
        <v-badge v-if="isUpdated" bordered color="error" icon="mdi-exclamation" overlap></v-badge>
        <div class="icons-container">
          <div v-if="showLock" data-test="lock-icon-container" class="overlay">
            <v-icon size="25" class="overlay-icon">mdi-lock</v-icon>
          </div>
          <div v-if="showUnlocked" data-test="unlocked-icon-container" class="overlay">
            <v-icon size="25" class="overlay-icon">mdi-lock-open</v-icon>
          </div>
          <div v-if="showNoBarcodes" data-test="no-barcode-icon-container" class="overlay">
            <v-icon size="25" class="overlay-icon">mdi-barcode-off</v-icon>
          </div>
        </div>

        <v-img v-if="!hover && brand.url" contain class="brand-card__logo mt-2" :src="brand.url"></v-img>
        <div
          v-if="hover || !brand.url"
          :style="(hover ? adjustFontSize(brand.name) : '') + borderStyle.color"
          class="text-center brand-card__text font-weight-regular px-2"
          :class="!hover ? 'text-truncate text-h6' : ''"
        >
          <span>{{ brand.name }}</span>
        </div>
        <v-img v-if="!hover && !brand.url" contain class="brand-card__logo--sw" :src="logoUrl"></v-img>
      </v-card>
    </v-hover>
  </div>
</template>

<script>
import colors from 'vuetify/lib/util/colors'
import { getSession } from '../functions/getSession'
import whitelabelFunctions from '../functions/whitelabel'

export default {
  props: ['brand'],
  computed: {
    logoUrl() {
      return whitelabelFunctions.siteWatermark(window.location.href)
    },
    isUpdated() {
      const brand = this.brand
      if (!this.$store.state.activeConfig.favorites.favoriteBrands.value.includes(brand.collection)) return false
      if (this.$route.query.feature !== 'brandupdated') return false
      if (!brand.lastSync) return true
      if (brand.lastSync < brand.lasttime) return true
      return false
    },
    staticClasses() {
      return 'staggerAnimation brand-card__card rounded-t-lg d-flex flex-column justify-center'
    },
    borderStyle() {
      let color
      const lightThemeColors = this.$vuetify.theme.themes.light
      // TODO figure out a better way of separating colors, some brands match more conditions
      // Because of that, the order here is important and that should not be the case
      if (!this.brand.hideBrand && this.brand.usedByTenant && !this.brand.source == 'latestCollection') color = lightThemeColors.info
      else if (!this.brand.hideBrand && this.brand.source == 'latestCollection') color = colors.green.base
      else if (this.brand.usedByTenant) color = lightThemeColors.warning
      else if (this.brand.hideBrand) color = lightThemeColors.error
      return { border: `border-top-color: ${color}`, color: `color: ${color}` }
    },
    showLock() {
      if (this.isOwner && this.isBrandPrivate && !this.showNoBarcodes) return true
      return this.hasPrivateProucts && !this.hasAccess
    },
    showUnlocked() {
      if (this.isOwner && !this.isBrandPrivate && !this.showNoBarcodes) return true
      return this.hasPrivateProucts && this.hasAccess
    },
    showNoBarcodes() {
      return this.brand.count == 0 || !this.brand.count
    },
    hasPrivateProucts() {
      return !this.showNoBarcodes && this.isBrandPrivate
    },
    isBrandPrivate() {
      if (Array.isArray(this.brand.associates) && this.brand.associates) return true
      return false
    },
    isOwner() {
      return this.brand.ownerTenantId === getSession().current_tenant
    },
    isMaster() {
      if (getSession().current_tenant == 'master') return true
      return false
    },
    hasAccess() {
      if (this.isBrandPrivate) {
        if (this.brand.associates?.find((associate) => associate.tenantId === getSession().current_tenant && associate.status === 'approved')) return true
      }
      return false
    },
  },
  methods: {
    adjustFontSize(brandName) {
      const cardWidth = this.$refs.brandCardDiv.offsetWidth
      const cardHeight = this.$refs.brandCardDiv.offsetHeight
      let longestWordLength = 0
      // Set the logenst word's length of the brand name
      for (const word of brandName.split(' ')) if (word.length > longestWordLength) longestWordLength = word.length
      // Add the total words also to the length to make the font smaller for long names
      longestWordLength += brandName.split(' ').length
      // Make the font smaller if the height is smaller than the width, so for landscape view of the cards
      if (cardHeight < cardWidth) return `font-size: ${((cardWidth + cardHeight) / longestWordLength) * 0.6}px;`
      return `font-size: ${(cardWidth / longestWordLength) * 1.4}px;`
    },
  },
}
</script>

<style lang="scss" scoped>
.brand-card {
  &__card {
    border-top-style: solid;
    border-width: 2px;
    height: 140px;
  }
  &__logo {
    &--sw {
      max-width: 50%;
      margin-left: 25%;
      max-height: 30%;
    }
    padding: 10px;
    max-width: 90%;
    margin-left: 5%;
    max-height: 90%;
  }
}
.overlay {
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.icons-container {
  z-index: 1;
  position: absolute;
  top: 18px;
  right: 18px;
  display: flex;
}

.overlay-icon {
  color: rgba(209, 51, 38, 1);
  margin-right: 8px;
}
.overlay-icon-second {
  color: rgba(209, 51, 38, 1);
  margin-right: 8px;
  right: 8px;
}
</style>
