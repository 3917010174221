<template>
  <div v-hotkey="keymap" class="mx-0">
    <swAuth role="products">
      <v-toolbar v-if="noRouteQuery" class="sticky">
        <swBreadCrumbs :breadcrumbs="breadcrumbs" />
      </v-toolbar>
    </swAuth>
    <v-toolbar v-if="$route.query.brandId" fixed class="breadcrumbs">
      <v-toolbar-title>
        <span>
          <swBreadCrumbs :breadcrumbs="breadcrumbs" @callback="selectedProduct = null" />
        </span>
      </v-toolbar-title>
      <v-chip class="pa-2 red white--text">
        {{ filteredProducts.length }}
      </v-chip>
      <v-spacer></v-spacer>
      <v-btn v-if="showPreviousNextProductButton" icon large dark class="primary mx-1" @click="prevProduct">
        <v-icon>mdi-chevron-left</v-icon>
      </v-btn>
      <v-btn v-if="showPreviousNextProductButton" icon large dark class="primary mx-1" @click="nextProduct">
        <v-icon>mdi-chevron-right</v-icon>
      </v-btn>
      <div v-show="showSpacer" class="drawer__spacer">
        <!-- space to offset the visible drawer -->
      </div>
    </v-toolbar>

    <v-container v-if="whatToShow.brandsGrid">
      <swBrandsGrid :filter="$store.state.filter" :bpopup="$route.query.brandPopUp" />
    </v-container>

    <v-container v-if="whatToShow.productsGrid">
      <!-- Filter controls -->
      <v-container class="mt-4 pb-0 pt-0">
        <v-row>
          <v-col cols="5">
            <v-combobox v-model="collectionFilter" outlined hide-details :items="brandCollections" :label="swT('collection')" multiple small-chips deletable-chips></v-combobox>
          </v-col>
          <v-col cols="2" align="center">
            <swAuth role="lc-b2b,lc-data-only">
              <template v-slot:otherwise>
                <v-icon v-if="filterOwnData" center x-large @click="filterOwnData = !filterOwnData">mdi-database-check</v-icon>
                <v-icon v-else center x-large @click="filterOwnData = !filterOwnData">mdi-database</v-icon>
              </template>
            </swAuth>
          </v-col>
          <v-col cols="5">
            <v-combobox
              v-model="productGroupFilter"
              outlined
              hide-details
              :items="brandProductGroups"
              :label="swT('productgroup')"
              multiple
              small-chips
              deletable-chips
            ></v-combobox>
          </v-col>
        </v-row>
      </v-container>

      <swProductsGrid v-if="whatToShow.productsGrid" :products="filteredProducts.slice(0, 100)" :loader-dialog="loaderDialog" @update:selected="selectProduct($event)" />
    </v-container>

    <swAuth role="lc-b2b,lc-data-only">
      <swProductB2BForm v-if="whatToShow.productForm && selectedProduct" class="mt-0" :product="selectedProduct" :brand-products="brandProducts" :brand="$route.query.brandId" />
      <template v-slot:otherwise>
        <swProductForm
          v-if="whatToShow.productForm && selectedProduct"
          class="mt-0"
          :product="selectedProduct"
          :brand-products="brandProducts"
          :brand="$route.query.brandId"
          @save="saveProduct($event)"
        />
      </template>
    </swAuth>
    <swScrollTopFab />
    <v-dialog v-model="loaderDialog" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          {{ swT('standby') }}
          <v-progress-linear indeterminate color="white" class="mb-0"></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import { swT } from '@/functions/i18n'
import swScrollTopFab from '../components/swScrollTopFab.vue'
import swBreadCrumbs from '../components/swBreadCrumbs.vue'
import swProductForm from '../components/swProductForm.vue'
import swProductB2BForm from '../components/swProductB2BForm.vue'
import swBrandsGrid from '../components/swBrandsGrid.vue'
import swProductsGrid from '../components/swProductsGrid.vue'
import globalStore from '../store/globalStore'
import productFunctions from '../functions/products'
import userFunctions from '../functions/users'
import tools from '../functions/tools'
import consts from '../store/consts'
import { showDialog } from '../functions/dialogService'
import { eventBus } from '../main'
import { findSkuByBarcode, hashBrand } from '@softwear/latestcollectioncore'
import { getSession } from '../functions/getSession'

export default {
  components: {
    swScrollTopFab,
    swBreadCrumbs,
    swProductForm,
    swProductB2BForm,
    swBrandsGrid,
    swProductsGrid,
  },
  props: ['query'],
  data() {
    return {
      swT,
      brandFilter: '',
      brandProducts: [],
      collectionFilter: [],
      filterOwnData: false,
      fab: false,
      loaderDialog: false,
      ping: 0,
      productDialog: false,
      productGroupFilter: [],
      productViewMode: 0,
      selectedProduct: null,
      selectedProducts: [],
      inventoryManagementUpdate: {},
      whatToShow: {
        brandsGrid: false,
        productsGrid: false,
        productForm: false,
      },
    }
  },
  computed: {
    breadcrumbs() {
      const crumb1 = {
        text: swT('brands'),
        disabled: false,
        to: '/products',
        callback: () => this.chooseView('brandsGrid'),
      }
      const brandId = hashBrand(this.query?.brandId)
      const brand = this.$store.state.brands.find((brand) => brand.collection == brandId)

      const dataProvider = ['', undefined].includes(this.query?.dataProvider) ? '' : this.query.dataProvider + '/'
      const brandText = brand ? dataProvider + brand?.name : '' || brandId

      const link = `/products?brandId=${brandId}`
      if (this.query?.feature) link.concat(`&feature=${this.query.feature}`)
      if (this.query?.dataProvider) link.concat(`&dataProvider=${this.query.dataProvider}`)

      const crumb2 = {
        text: brandText,
        disabled: !this.selectedProduct,
        to: link,
        callback: () => this.chooseView('productsGrid'),
      }
      if (!this.selectedProduct) return [crumb1, crumb2].filter((crumb) => crumb.text.length)

      const crumb3 = {
        text: this.selectedProduct.articleCodeSupplier || this.selectedProduct.articleCode,
        disabled: true,
        to: '',
      }
      return [crumb1, crumb2, crumb3].filter((crumb) => crumb.text && crumb.text.length)
    },
    noRouteQuery() {
      return Object.keys(this.query || {}).length === 0
    },
    keymap() {
      return {
        'ctrl+<': this.prevProduct,
        'ctrl+>': this.nextProduct,
        enter: this.switchToBarcodeMode,
      }
    },
    hasProductRole() {
      const tenant = getSession().current_tenant
      const roles = getSession().roles[tenant]
      return userFunctions.hasRole(roles, 'products')
    },
    brand() {
      return hashBrand(this.query.brandId)
    },
    skuEditorSkus() {
      return productFunctions.getBrandSkus(this.brand)
    },
    showPreviousNextProductButton() {
      // We are hiding the buttons and wait for customer response.
      // We intent to remove them entirely because they clumsy on mobile and there
      // are better ways to navigate.
      return false
      //   return !this.$store.state.editing && this.whatToShow.productForm && this.selectedProduct && !userFunctions.hasRole(this.$store.getters.roles, 'lc-b2b,lc-data-only')
    },
    showSpacer() {
      return this.$vuetify.breakpoint.lgAndUp && this.$store.state.drawer
    },
    brandProductGroups() {
      return tools.getUniqueValues(this.brandProducts.map((e) => e.articleGroup)).sort()
    },
    brandCollections() {
      return tools
        .getUniqueValues(this.brandProducts.map((e) => e.collection))
        .sort()
        .reverse()
    },
    filteredProducts() {
      // We can filter by:
      //
      // Barcode: when a 12/13 digit number is used as filter, a barcode lookup is performed.
      //
      // ArticleCode: a list of strings typed by the user. Matches when one of those stings is found as part of an articleCode
      // Groups: a list of values for articleGroup and collection selected by the user
      //
      if ((this.$store.state.filter?.length == 12 || this.$store.state.filter?.length == 13) && !isNaN(this.$store.state.filter)) {
        return []
      }
      // Make a filters array with all comma separated filer values typed by the user
      const filters = this.$store.state.filter ? this.$store.state.filter.toLowerCase().split(',') : []
      // Remove possible empty filter when user ends his quest by a ,
      if (!filters[filters.length - 1]) filters.pop()
      return this.brandProducts.filter((product) => {
        // Filter out inactive products
        if (!product.active) return false
        // filter own data if applicable
        if (this.filterOwnData && !product.usedByTenant) return false
        // Perform case insensitive searches
        const articleCode = product.articleCode?.toLowerCase() || ''
        const articleDescription = product.articleDescription?.toLowerCase() || ''

        // If there are no filter values, we are GO to include this item in the search
        // Otherwise set searchStatus to NO GO until we find a match on one of the filtervalues
        let searchStatus = filters.length > 0 ? 'NO GO' : 'GO'
        for (const f of filters)
          if (articleCode.includes(f) || articleDescription.includes(f)) {
            searchStatus = 'GO'
            continue
          }

        if (filters && searchStatus == 'NO GO') return false
        if (this.productGroupFilter.length > 0 && this.productGroupFilter.indexOf(product.articleGroup) == -1) return false
        if (this.collectionFilter.length > 0 && this.collectionFilter.indexOf(product.collection) == -1) return false
        return true
      })
    },
  },
  watch: {
    async '$store.state.scannedBarcode'(newValue) {
      // Vue will fire this watch on all components cached by <keep-alive>.
      // We only need to repsond on the active component
      if (this._inactive == true) return

      if (this.$store.state.editing) return

      // If not a valid barcode
      if (!newValue || (newValue.length != 13 && newValue.length != 12) || isNaN(newValue) || newValue.indexOf(',') != -1) {
        this.$store.dispatch('clearFilter')
        // Show alert only if there is a value in newValue to prevent showing the alert when getting the field empty
        if (newValue)
          this.$store.dispatch('raiseAlert', {
            header: 'incorrect_barcode',
            type: 'warning',
            timeout: 3000,
          })
        return
      }

      // Perform barcode search
      // First, look for this barcode in the tenantSkus
      const tenantSkus = globalStore.getLatestCollectionObject('sku')
      const barcode = newValue
      const tenantSku = findSkuByBarcode(tenantSkus, barcode)

      // 8718218298776
      // Set the tenantSku.id as a productId, because the given barcode might have not correct controldigit
      // wich will cause an error by navigation, examples:
      // - barcode: 091201409747, tenantSku.id: 0091201409747   // without a controldigit
      // - barcode: 0091201409745, tenantSku.id: 0091201409747  // with a wrong controldigit
      if (tenantSku) return this.redirectToProductView(tenantSku.brand, tenantSku.id)

      // So it was not a barcode in the tenant data...
      // Ask the server
      this.$store.dispatch('getBarcode', {
        barcode: barcode,
        callback: this.procBarcode,
      })
    },
    // When a barcode appears in the filter box switch to barcode scan mode
    '$store.state.filter'() {
      // Vue will fire this watch on all components cached by <keep-alive>.
      // We only need to repsond on the active component
      if (this._inactive == true) return

      if (this.$store.state.editing) return
    },
    query: {
      handler() {
        if (this.noRouteQuery) return this.chooseView('brandsGrid')
        if (this.query.productId || this.query.ean) this.findAndShowProduct()
        if (this.query.brandId) this.prepareBrandProducts()
        if (this.query.brandPopUp) this.chooseView('brandsGrid')
      },
      deep: true,
      immediate: true,
    },
  },
  activated() {
    this.$store.dispatch('clearFilter')
    this.updateMainFilterPrompt()
    if (this.whatToShow.productsGrid) this.$store.state.mainFilterPrompt = swT('find_product_articlecode')
  },
  deactivated() {
    if (this.$route.path === '/products') return
    this.updateMainFilterPrompt()
    this.$store.state.productPickerActive = false
    this.$store.state.barcodeScannerMode = false
  },
  async created() {
    eventBus['$on']('inventoryManagementUpdate', this.updateInventoryManagement)
    eventBus['$on']('save', this.saveProduct)
    eventBus['$on']('cancel', this.cancel)
    eventBus['$on']('selectB2BProduct', this.selectB2BProduct)

    this.consts = consts // Add non-reactive consts
    this.selectedProduct = null
    this.$store.dispatch('clearFilter')
  },
  destroyed() {
    eventBus['$off']('inventoryManagementUpdate', this.updateInventoryManagement)
    eventBus['$off']('save')
    eventBus['$off']('cancel')
    eventBus['$off']('selectB2BProduct')
  },
  methods: {
    chooseView(view) {
      this.whatToShow = {
        brandsGrid: false,
        productsGrid: false,
        productForm: false,
      }
      this.whatToShow[view] = true
    },
    selectB2BProduct(productCode) {
      this.selectedProduct = this.brandProducts.find((product) => product.articleCodeSupplier == productCode)
      this.chooseView('productForm')
    },
    selectProduct(product) {
      if (product?.articleCode) this.selectedProduct = productFunctions.getProductDetails(product)
      else this.selectedProduct = product
      this.chooseView('productForm')
    },
    async goToSkuEdit() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'sw')) {
        await this.$store.dispatch('loadBrand', { brand: this.query.brandId, dataProvider: '' })
      }
      if (userFunctions.hasRole(this.$store.getters.roles, 'products-dataprovider_admin')) {
        await this.$store.dispatch('loadBrand', { brand: this.query.brandId, dataProvider: getSession().latestCollectionSettings.dataProviderAdmin })
      }
      this.$router.push({ path: 'skueditor', query: { brand: this.query.brandId } })
    },
    updateInventoryManagement({ warehouseId, barcode, property, qty }) {
      this.$store.dispatch('setEditing', true)
      this.$store.state.activeFormValid = true
      if (!this.inventoryManagementUpdate[barcode]) this.inventoryManagementUpdate[barcode] = { data: {} }
      if (!this.inventoryManagementUpdate[barcode].data[warehouseId]) this.inventoryManagementUpdate[barcode].data[warehouseId] = {}
      this.inventoryManagementUpdate[barcode].data[warehouseId][property] = qty || 0
    },
    async refreshProducts(force) {
      // Ideally, the next lines should have been computed properties but on
      // 250.000 skus, that's too slow
      // So here we do the 'reactivity' manually
      let skus = []
      this.loaderDialog = true
      if (force) globalStore.resetLatestCollectionAPI('selectedbrand')

      const cachedBrand = (await globalStore?.getLatestCollectionAPI('selectedbrand')?.brand) || ''
      const newData = await productFunctions.setBrandContext(this.$store, this.query.brandId, this.query.dataProvider, cachedBrand)
      skus = globalStore.getBrandSkus()
      if (newData || force) {
        globalStore.setIndexedBrandProducts(productFunctions.buildProductIndexedObject(skus))
        this.brandProducts = productFunctions.getBrandProducts(skus, this.$store.getters.sizeOrdering, this.$store.getters.mapSubSizes)
      } else {
        // skus = globalStore.getBrandSkus()
        this.brandProducts = globalStore.getBrandProducts()
      }
      this.loaderDialog = false
    },
    async refreshDB() {
      await this.$store.dispatch('refreshDB')
      await this.refreshProducts()
    },
    cancel() {
      this.$store.dispatch('setEditing', false)
    },
    async saveProduct() {
      // Send updated inventoryManagement parameters to server
      if (this.inventoryManagementUpdate) {
        await this.$store.dispatch('updateObjects', {
          api: globalStore.getLatestCollectionAPI('inventorymanagement'),
          data: Object.keys(this.inventoryManagementUpdate).map((key) => {
            return { id: key, ...this.inventoryManagementUpdate[key] }
          }),
          auditHeaders: {
            'x-transaction-audit-source': 'Save product',
          },
        })
      }
      this.$store.dispatch('setEditing', false)
    },
    prevProduct() {
      if (this.$store.state.editing) return
      if (!this.selectedProduct) return
      // Find previous product within filter and set this.selectedProduct accordingly
      for (const i in this.filteredProducts) {
        if (this.filteredProducts[i].articleCode == this.selectedProduct.articleCode) {
          let j = 1
          while (j <= i) {
            let nextI = ''
            nextI = parseInt(i) - j + ''
            if (this.filteredProducts[nextI] && this.filteredProducts[nextI].usedByTenant) {
              this.selectedProduct = productFunctions.getProductDetails(this.filteredProducts[nextI])
              return
            }
            j++
          }
        }
      }
      this.chooseView('productsGrid')
    },
    nextProduct() {
      if (this.$store.state.editing) return
      if (!this.selectedProduct) return
      // Find next product within filter and set this.selectedProduct accordingly
      for (const i in this.filteredProducts) {
        if (this.filteredProducts[i].articleCode == this.selectedProduct.articleCode) {
          let j = 1
          while (j < this.filteredProducts.length) {
            let nextI = ''
            nextI = parseInt(i) + j + ''
            if (this.filteredProducts[nextI] && this.filteredProducts[nextI].usedByTenant) {
              this.selectedProduct = productFunctions.getProductDetails(this.filteredProducts[nextI])
              return
            }
            j++
          }
        }
      }
      this.chooseView('productsGrid')
    },
    async prepareBrandProducts() {
      await this.$store.state.skuPromise
      await this.refreshProducts(true)
      // Everything is now ready for the Brands view but if a productId is given, this serves only as context for the productForm...
      if (this.query.productId) {
        this.selectedProduct = {}
        let productFound = false
        for (const product of this.brandProducts) {
          // Find product in brand products
          // First check for a match on articleCode
          if (product.articleCode == this.query.productId) {
            this.selectedProduct = product
            productFound = true
            break
          }
          // Now check for a match on barcode
          if (this.query.productId.length == 12 || this.query.productId.length == 13) {
            product.SKUS.forEach((sku) => {
              if (sku.barcode == this.query.productId) {
                this.selectedProduct = product
                productFound = true
              }
            })
          }
          if (productFound) break
        }
        if (!productFound) return await showDialog('swInfoDialog', { message: swT('productnotfound'), routeOnOK: 'products' })
        // Prepare the Product view
        // Our product is constructed from non-reactive data. Make a reactive copy.
        this.selectedProduct = productFunctions.getProductDetails(this.selectedProduct)
        this.chooseView('productForm')
      } else {
        this.chooseView('productsGrid')
      }
    },
    redirectToProductView(brandId, productId, dataProvider) {
      this.$router
        .push({
          path: 'products',
          query: {
            brandId: brandId,
            productId: productId,
            dataProvider: dataProvider,
          },
        })
        .catch((err) => err)
      this.chooseView('productForm')
    },
    async findAndShowProduct() {
      await this.$store.state.skuPromise
      for (const p of globalStore.getLatestCollectionArray('sku')) {
        if (p.articleCode == this.query.productId) return this.redirectToProductView(p.brand, p.articleCode)
        if (p.id == this.query.productId) return this.redirectToProductView(p.brand, p.id)
        if (p.id == this.query.ean) return this.redirectToProductView(p.brand, p.id)
      }
    },
    procBarcode(barcode) {
      if (!barcode) {
        this.$store.dispatch('clearFilter')
        return this.$store.dispatch('raiseAlert', {
          header: 'unknownbarcode',
          type: 'warning',
          timeout: 3000,
        })
      }
      this.redirectToProductView(barcode.brand, barcode.id, barcode.dataProvider)
    },
    switchToBarcodeMode(event) {
      if (event.key !== 'Enter' || this.$route.path !== '/products') return

      // If the value is a barcode value, switch to barcode mode
      const value = event.target.value
      if (value && [12, 13].includes(value.length) && !isNaN(value) && value.indexOf(',') == -1) {
        this.$store.state.productPickerActive = true
        this.$store.state.barcodeScannerMode = true
        this.$store.state.scannedBarcode = value
      }
    },
    updateMainFilterPrompt() {
      const tenant = getSession().current_tenant
      const roles = getSession().roles[tenant]
      if (userFunctions.hasRole(roles, 'lc-data-only')) {
        if (this.$route.query.brandId) this.$store.state.mainFilterPrompt = swT('find_product')
        else this.$store.state.mainFilterPrompt = swT('find_brand')
      } else if (userFunctions.hasRole(roles, 'lc-b2b')) {
        if (this.$route.query.brandId) this.$store.state.mainFilterPrompt = swT('find_product')
        else this.$store.state.mainFilterPrompt = swT('')
      } else {
        if (this.$route.query.brandId) this.$store.state.mainFilterPrompt = swT('find_product_or_barcode')
        else this.$store.state.mainFilterPrompt = swT('find_brand_or_barcode')
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.products {
  &__toolbar {
    &--main {
      top: 64px;
      position: sticky;
      width: 100%;
      z-index: 3;
    }
  }
  &__spacer {
    width: 256px;
  }
}
</style>
