<template>
  <div v-if="brands.length > 0">
    <h2 class="staggerAnimation text-xl-h4 py-2" primary-title :data-test="`${title ? title.toLowerCase() : ''}`">{{ title }}</h2>
    <v-row dense>
      <v-col
        v-for="(brand, index) in visibleBrands"
        :key="brand.collection + index"
        :data-test="'brand-' + brand.collection"
        cols="6"
        sm="4"
        md="3"
        lg="1"
        xl="1"
        @click="selectBrand(brand)"
      >
        <swBrandCard :brand="brand" />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import userFunctions from '../functions/users'
import swBrandCard from './swBrandCard.vue'
import { getSession } from '../functions/getSession'

export default {
  components: {
    swBrandCard,
  },
  props: ['brands', 'title'],
  computed: {
    visibleBrands() {
      const tenant = getSession().current_tenant
      const roles = getSession().roles[tenant]

      if (!userFunctions.hasRole(roles, 'sw')) {
        return this.brands.filter((b) => (!b.hideBrand || b?.usedByTenant) && b.name != 'masterdata')
      } else {
        return this.brands.filter((b) => b.name != 'masterdata')
      }
    },
  },
  methods: {
    selectBrand(brand) {
      this.$emit('select', brand)
    },
  },
}
</script>
