<template>
  <v-dialog persistent :value="showEditSku" max-width="800" width="600px">
    <v-card class="pb-2 white">
      <v-card class="sticky-element px-2 mb-2 mx-0">
        <v-card-text>
          <v-row data-test="brand-title mx-1 px-0">
            <v-col cols="12" sm="3" class="d-flex align-center justify-start">
              <div class="text-h5">{{ swT(convertMode(mode)) }}</div>
            </v-col>
            <v-col cols="12" sm="9" class="d-flex align-center justify-end">
              <v-card-actions>
                <span v-if="computedMode == 'add-sku'">
                  <img ref="succeedAnimation" :src="animationSrc" class="succeed-animation" contain />
                  <v-btn v-if="!readonly" :disabled="!$store.state.activeFormValid" data-test="addSkuButton" large class="success" @click.native="addSku">
                    {{ swT('add_sku') }}
                  </v-btn>
                  <v-btn class="ml-2 error" data-test="doneButton" large @click.native="doneAddingSkus">
                    {{ swT('close') }}
                  </v-btn>
                </span>

                <span v-if="computedMode == 'edit-sku' || computedMode == 'edit-masterdata'">
                  <v-btn v-if="!readonly" large class="mx-1 success" :disabled="!$store.state.activeFormValid" data-test="okEditButton" @click.native="updateSku">
                    {{ swT('ok') }}
                  </v-btn>
                  <v-btn large class="mx-1 error" data-test="cancelEditButton" @click.native="cancelForm">
                    {{ swT('cancel') }}
                  </v-btn>
                </span>

                <span v-if="computedMode == 'add-masterdata'">
                  <v-btn v-if="!readonly" class="mx-1 success" large :disabled="!$store.state.activeFormValid" data-test="addSkuButton" @click="addMasterData">
                    {{ swT('add') }}
                  </v-btn>
                  <v-btn class="mx-1 error" large data-test="doneButton" @click.native="cancelForm">
                    {{ swT('done') }}
                  </v-btn>
                </span>

                <span v-if="computedMode == 'display-data'">
                  <v-btn class="mx-1 error" large data-test="doneButton" @click.native="cancelForm">
                    {{ swT('done') }}
                  </v-btn>
                </span>
              </v-card-actions>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      <v-card-text class="mt-2">
        <v-form ref="form" v-model="$store.state.activeFormValid" lazy-validation class="mx-0">
          <v-row v-if="!masterdata">
            <v-col>
              <div v-show="barcodeLoading" class="text-center">
                <v-progress-circular :width="3" :size="48" color="black" indeterminate></v-progress-circular>
              </div>

              <v-img
                v-show="!barcodeLoading && uiSku.barcode"
                :src="uiSku.barcode && uiSku.barcode.length >= 12 ? `http://bwipjs-api.metafloor.com/?bcid=code128&text=${uiSku.barcode}` : ''"
                height="48"
              ></v-img>

              <v-slide-group v-if="sku.images && skuImages(sku).length > 1" v-model="slideGroupModel" center-active show-arrows class="py-1">
                <v-slide-item v-for="image in skuImages(sku)" :key="image" v-slot="{ active, toggle }">
                  <v-card flat class="mx-1 d-flex align-center" height="200" width="100" @click="toggle">
                    <v-img :src="imageSrc(image, 150)" contain></v-img>
                  </v-card>
                </v-slide-item>
              </v-slide-group>

              <v-text-field
                v-show="!isGeneratingBarcodes"
                id="barcodeField"
                ref="barcodeField"
                v-model="uiSku.barcode"
                v-hotkey="keymap"
                class="mt-2"
                data-test="barcodeField"
                outlined
                dense
                autofocus
                type="number"
                :disabled="['edit-sku', 'display-data'].includes(computedMode)"
                :label="swT('barcode')"
                :rules="[isGeneratingBarcodes ? true : required, isGeneratingBarcodes ? true : (v) => !v || v.length == 13 || v.length == 12 || swT('lengthshouldbe12or13')]"
                :error-messages="barcodeErrorMessage"
                @blur="validateBarcode"
                @enter="validateBarcode"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row class="flex-wrap">
            <v-col v-if="uiSku.images && skuImages(sku).length < 2" cols="12">
              <v-img :src="imageSrc(skuImages(sku)[0], 150)" contain></v-img>
            </v-col>

            <!-- articleCodeSupplier -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.articleCodeSupplier"
                class="mt-2"
                data-test="articleCodeSupplier"
                outlined
                :disabled="computedMode == 'display-data'"
                hide-details
                dense
                :label="swT('articlecodesupplier')"
                :rules="[required]"
                @keyup="updateFieldLevelFind"
                @click="$event.target.select()"
              ></v-text-field>
            </v-col>

            <!-- articleDescription -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.articleDescription"
                class="mt-2"
                :disabled="computedMode == 'display-data'"
                data-test="articleDescriptionField"
                outlined
                hide-details
                dense
                :label="swT('articledescription')"
                :rules="[required]"
                @click="$event.target.select()"
              ></v-text-field>
            </v-col>

            <!-- sizeRange -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-autocomplete
                ref="sizeRange"
                v-model="selectedSizeRange"
                class="mt-2"
                :disabled="computedMode == 'display-data'"
                data-test="sizeRange"
                dense
                outlined
                clearable
                hide-details
                :items="$store.state.activeConfig.products.sizeranges.value.split('\n')"
                :label="swT('size_range')"
                @change="handleSizeRangeChange"
              ></v-autocomplete>
            </v-col>

            <!-- size -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-combobox
                v-if="selectedSizeRange && isGeneratingBarcodes"
                ref="sizeField"
                v-model="uiSku.size"
                class="mt-2"
                data-test="sizeField"
                dense
                clearable
                :disabled="computedMode == 'display-data'"
                outlined
                hide-details
                small-chips
                hide-selected
                :multiple="isGeneratingBarcodes"
                :items="selectedSizeRange.split(',')"
                :rules="[required]"
                :label="swT('size')"
                :deletable-chips="isGeneratingBarcodes"
              >
                <template v-slot:selection="{ attrs, item, selected, index }">
                  <v-chip v-bind="attrs" :input-value="selected" label small>
                    <span :class="isGeneratingBarcodes ? 'pr-1' : ''" :data-test="`groupChip-${item.text}`">{{ item }}</span>
                    <v-icon v-if="isGeneratingBarcodes && Array.isArray(uiSku.size)" small @click="uiSku.size.splice(index, 1)">$delete</v-icon>
                  </v-chip>
                </template>
              </v-combobox>

              <v-select
                v-else-if="selectedSizeRange && !isGeneratingBarcodes"
                ref="sizeField"
                v-model="uiSku.size"
                class="mt-2"
                data-test="sizeField"
                dense
                clearable
                :disabled="computedMode == 'display-data'"
                outlined
                hide-details
                small-chips
                :items="selectedSizeRange.split(',')"
                hide-selected
                :rules="[required]"
                :label="swT('size')"
                :deletable-chips="isGeneratingBarcodes"
              >
                <template v-slot:selection="{ attrs, item, selected, index }">
                  <v-chip v-bind="attrs" :input-value="selected" label small>
                    <span :class="isGeneratingBarcodes ? 'pr-1' : ''" :data-test="`groupChip-${item.text}`">{{ item }}</span>
                    <v-icon v-if="isGeneratingBarcodes && Array.isArray(uiSku.size)" small @click="uiSku.size.splice(index, 1)">$delete</v-icon>
                  </v-chip>
                </template>
              </v-select>

              <v-text-field
                v-else
                ref="sizeField"
                v-model="uiSku.size"
                class="mt-2"
                data-test="sizeField"
                dense
                clearable
                :disabled="computedMode == 'display-data'"
                outlined
                hide-details
                small-chips
                :items="selectedSizeRange.split(',')"
                hide-selected
                :rules="[required]"
                :label="swT('size')"
                :deletable-chips="isGeneratingBarcodes"
              >
                <template v-slot:selection="{ attrs, item, selected, index }">
                  <v-chip v-bind="attrs" :input-value="selected" label small>
                    <span :class="isGeneratingBarcodes ? 'pr-1' : ''" :data-test="`groupChip-${item.text}`">{{ item }}</span>
                    <v-icon v-if="isGeneratingBarcodes && Array.isArray(uiSku.size)" small @click="uiSku.size.splice(index, 1)">$delete</v-icon>
                  </v-chip>
                </template>
              </v-text-field>
            </v-col>

            <!-- color -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-combobox
                v-model="uiSku.colorFamily"
                class="mt-2"
                data-test="colorField"
                dense
                :disabled="computedMode == 'display-data'"
                outlined
                hide-details
                :items="colors"
                :label="swT('color')"
                :rules="[required]"
              ></v-combobox>
            </v-col>

            <!-- colorCodeSupplier -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.colorCodeSupplier"
                class="mt-2"
                data-test="colorCodeSupplierField"
                dense
                outlined
                :disabled="computedMode == 'display-data'"
                hide-details
                :label="swT('colorcodesupplier')"
                @click="$event.target.select()"
              ></v-text-field>
            </v-col>

            <!-- colorSupplier -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.colorSupplier"
                :disabled="computedMode == 'display-data'"
                class="mt-2"
                data-test="colorSupplierField"
                dense
                outlined
                hide-details
                :label="swT('colorsupplier')"
              ></v-text-field>
            </v-col>

            <!-- articleGroup -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-combobox
                v-model="uiSku.articleGroup"
                class="mt-2"
                :disabled="computedMode == 'display-data'"
                data-test="articleGroupField"
                dense
                outlined
                hide-details
                :items="$store.state.productGroups"
                :label="swT('productgroup')"
                :rules="[required]"
              ></v-combobox>
            </v-col>

            <!-- brand -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-combobox
                ref="brandField"
                v-model="uiSku.brand"
                :disabled="computedMode == 'display-data'"
                class="mt-2"
                data-test="brandField"
                dense
                outlined
                hide-details
                :items="$store.state.brands.map((b) => b.name).sort()"
                :label="swT('brand')"
                :rules="[required]"
              ></v-combobox>
            </v-col>

            <!-- collection -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-combobox
                v-model="uiSku.collection"
                class="mt-2"
                :disabled="computedMode == 'display-data'"
                data-test="collectionField"
                dense
                outlined
                hide-details
                :items="$store.state.collections"
                :label="swT('collection')"
                :rules="[required]"
              ></v-combobox>
            </v-col>

            <!-- buyPrice -->
            <v-col v-if="!masterdata" cols="12" :sm="defaultDiscount != '0.0%' ? '3' : '6'">
              <v-currency-field
                v-model="uiSku.buyPrice"
                class="mt-2 right-input"
                data-test="buyPriceField"
                outlined
                :disabled="computedMode == 'display-data' || enableDiscount"
                hide-details
                dense
                prefix="€"
                :label="swT('buyprice')"
                @click="$event.target.select()"
                @input="priceChange"
              ></v-currency-field>
            </v-col>

            <!-- price -->
            <v-col v-if="!masterdata" cols="12" :sm="defaultDiscount != '0.0%' ? '3' : '6'">
              <v-currency-field
                v-model="uiSku.price"
                class="mt-2 right-input"
                data-test="priceField"
                outlined
                hide-details
                :disabled="computedMode == 'display-data'"
                dense
                prefix="€"
                :label="swT('sellprice')"
                @click="$event.target.select()"
                @input="priceChange"
              ></v-currency-field>
            </v-col>

            <!-- set of skus -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-combobox
                v-model="uiSku.set"
                class="mt-2"
                :disabled="computedMode == 'display-data'"
                data-test="setField"
                dense
                outlined
                hide-details
                :items="getItems('set')"
                :label="swT('set')"
              ></v-combobox>
            </v-col>

            <!-- default discount -->
            <v-col v-if="!masterdata && defaultDiscount != '0.0%'" cols="12" sm="6">
              <v-btn
                size="x-large"
                block
                :elevation="enableDiscount ? 0 : 4"
                class="mr-2 discount_button"
                data-test="enableDiscountButton"
                :append-icon="enableDiscount ? 'mdi-check-outline' : 'mdi-close-outline'"
                @click.native="calculateBuyPriceDiscount"
              >
                {{ defaultDiscount }} {{ swT('discount') }}
                <v-icon v-if="enableDiscount" right class="green--text">mdi-check-outline</v-icon>
              </v-btn>
            </v-col>

            <!-- margin -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <v-text-field v-model="margin" class="mt-2 right-input" data-test="marginField" outlined hide-details dense readonly filled :label="swT('margin')"></v-text-field>
            </v-col>

            <!-- calculation -->
            <v-col v-if="!masterdata && uiSku.buyPrice && uiSku.price" cols="12" sm="6">
              <v-text-field
                v-model="calculation"
                class="mt-2 right-input"
                data-test="calculationField"
                outlined
                hide-details
                dense
                readonly
                filled
                :label="swT('calculation')"
              ></v-text-field>
            </v-col>
            <v-col v-else cols="12" sm="6">
              <v-text-field
                value="0.0"
                class="mt-2 right-input"
                data-test="calculationField"
                outlined
                hide-details
                dense
                readonly
                filled
                :label="swT('calculation')"
              ></v-text-field>
            </v-col>

            <!-- default calculation -->
            <v-col v-if="!masterdata && defaultCalculation > 0.0" cols="12" sm="6">
              <v-text-field
                v-model="defaultCalculation"
                class="mt-2 right-input"
                data-test="defaultCalculationField"
                outlined
                hide-details
                dense
                readonly
                filled
                :label="swT('default_calculation')"
              ></v-text-field>
            </v-col>

            <!-- activeAttributes -->
            <v-col v-if="!masterdata" cols="12" sm="6">
              <div v-for="attribute in $store.state.activeConfig.products.activeAttributes.value" :key="attribute">
                <v-combobox
                  v-model="uiSku['_' + attribute]"
                  class="mt-2"
                  dense
                  outlined
                  hide-details
                  :disabled="disabledMasterdataFields('_' + attribute) || computedMode == 'display-data'"
                  :label="swT(attribute)"
                  :items="getItems('_' + attribute)"
                ></v-combobox>
              </div>
            </v-col>

            <!-- MASTERDATA -->

            <!-- articleGroup -->
            <v-col v-if="masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.articleGroup"
                data-test="masterdataProductGroupField"
                class="mt-2"
                dense
                outlined
                hide-details
                :disabled="disabledMasterdataFields('articleGroup') || computedMode == 'display-data'"
                :items="$store.state.productGroups"
                :rules="[required]"
                :label="swT('productgroup')"
              ></v-text-field>
            </v-col>

            <!-- masterdataSize -->
            <v-col v-if="masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.size"
                data-test="masterdataSizeField"
                class="mt-2"
                dense
                outlined
                hide-details
                small-chips
                :disabled="disabledMasterdataFields('size') || computedMode == 'display-data'"
                :rules="[required]"
                :label="swT('size')"
              ></v-text-field>
            </v-col>

            <!-- colorFamily -->
            <v-col v-if="masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.colorFamily"
                data-test="masterdataColorField"
                class="mt-2"
                dense
                outlined
                hide-details
                :disabled="disabledMasterdataFields('colorFamily') || computedMode == 'display-data'"
                :rules="[required]"
                :label="swT('color')"
              ></v-text-field>
            </v-col>

            <!-- collection -->
            <v-col v-if="masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.collection"
                data-test="masterdataCollectionField"
                class="mt-2"
                dense
                outlined
                hide-details
                :disabled="disabledMasterdataFields('collection') || computedMode == 'display-data'"
                :items="$store.state.collections"
                :rules="[required]"
                :label="swT('collection')"
              ></v-text-field>
            </v-col>

            <!-- collection -->
            <v-col v-if="masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.genderSupplier"
                data-test="masterdataGenderSupplierField"
                class="mt-2"
                dense
                outlined
                hide-details
                :disabled="disabledMasterdataFields('genderSupplier') || computedMode == 'display-data'"
                :items="$store.state.collections"
                :rules="[required]"
                :label="swT('sku.genderSupplier')"
              ></v-text-field>
            </v-col>

            <!-- collection -->
            <v-col v-if="masterdata" cols="12" sm="6">
              <v-text-field
                v-model="uiSku.set"
                data-test="masterdataSetField"
                class="mt-2"
                dense
                outlined
                hide-details
                :disabled="disabledMasterdataFields('set') || computedMode == 'display-data'"
                :items="$store.state.collections"
                :rules="[required]"
                :label="swT('sku.set')"
              ></v-text-field>
            </v-col>

            <!-- masterdata activeAttributes -->
            <v-col v-if="masterdata" cols="12" sm="6">
              <v-text-field
                v-for="attribute in $store.state.activeConfig.products.activeAttributes.value"
                :key="attribute"
                v-model="uiSku['_' + attribute]"
                class="mt-2"
                dense
                outlined
                hide-details
                :disabled="disabledMasterdataFields('_' + attribute) || computedMode == 'display-data'"
                :label="swT(attribute)"
              ></v-text-field>
            </v-col>

            <!-- TODO is this supposed to be here? -->
            <v-col v-if="masterdata" cols="12" sm="6">
              <h2 class="red--text">
                {{ barcodeErrorMessage }}
              </h2>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { swT } from '@/functions/i18n'
import globalStore from '../store/globalStore'
import webServices from '../functions/webServicesFacade'
import tools from '../functions/tools'
import { eventBus } from '../main'
import { deepCopy } from '@softwear/latestcollectioncore'
import { v4 as uuidv4 } from 'uuid'
import productFunctions from '../functions/products'
import fieldFunctions from '../functions/skuEditorFunctions'

export default {
  props: ['showEditSku', 'fields', 'updatedSkus', 'masterdata', 'sku', 'mode', 'readonly', 'isGeneratingBarcodes'],
  data() {
    return {
      swT,
      selectedSizeRange: '',
      barcodeErrorMessage: '',
      barcodeLoading: false,
      localMode: null,
      slideGroupModel: null,
      enableDiscount: false,
      animationSrc: require('@/assets/loading/succeed.gif'),
      noDocumentsAdded: true,
      isEditing: true,
      calculation: 0.0,
      defaultCalculation: 0.0,
      margin: 0,
    }
  },
  computed: {
    uiSku: {
      get: function() {
        return this.sku || productFunctions.newSku()
      },
      set: function(newValue) {
        return newValue
      },
    },
    computedMode() {
      return this.localMode || this.mode
    },
    fieldsToCheck() {
      return ['collection', 'articleGroup', 'size', 'colorFamily', 'genderSupplier', 'set'].concat(
        this.$store.state.activeConfig.products.activeAttributes.value.map((f) => '_' + f)
      )
    },
    keymap() {
      return {
        enter: this.validateBarcode,
      }
    },
    colors() {
      return this.$store.state.colors
    },
    defaultDiscount() {
      const brandSettings = this.getBrandSettings()
      const discountSetting = brandSettings?.discount

      if (discountSetting || discountSetting > 0) {
        return parseInt(discountSetting).toFixed(1) + '%'
      } else {
        const discount = 0.0
        return discount.toFixed(1) + '%'
      }
    },
  },
  watch: {
    uiSku: {
      handler() {
        if (Array.isArray(this.uiSku.size) && this.uiSku.size.length == 0) this.uiSku.size = []
        if (this.uiSku.size == '') this.uiSku.size = null
      },
      immediate: true,
      deep: true,
    },
    sku: {
      handler() {
        if (this.sku) {
          const propSku = deepCopy(this.sku)

          let brand
          if (propSku.brand) brand = this.$store.state.brands.find((b) => propSku.brand == b.id || propSku.brand == b.collection)?.name
          this.uiSku.brand = brand || propSku.brand

          if (!this.masterdata) {
            if (this.uiSku.size == '' || (Array.isArray(this.uiSku.size) && this.uiSku.size.length == 0)) {
              propSku.size = Array.isArray(propSku.size) ? propSku.size : [propSku.size]
              this.uiSku = propSku
            } else {
              const sizeCopy = this.uiSku.size
              this.uiSku = propSku
              this.uiSku.size = sizeCopy
            }

            if (this.sku.barcode) this.uiSku.size = this.sku.size
          } else {
            this.uiSku = propSku
          }
        }
      },
      deep: true,
      immediate: true,
    },
    showEditSku: {
      handler() {
        // Prevent showing the succeed animation when copy sku
        this.noDocumentsAdded = this.showEditSku
        this.isEditing = true
        // Reset the validation everytime we open the addSku form for a new document
        if (this.showEditSku && this.isNewDocument()) this.$refs.form.resetValidation()
      },
    },
    selectedSizeRange() {
      this.updateSkuSize()
    },
    value() {
      this.$nextTick(() => {
        if (this.computedMode === 'display-data') {
          // Removes the carets from the dropdowns, they are all disabled in this mode
          const elements = document.querySelectorAll('div.v-input__icon.v-input__icon--append')

          elements.forEach((element) => {
            element.setAttribute('style', 'display: none !important;')
          })
        }
        this.$refs['form'].resetValidation()
      })
    },
  },
  created() {
    this.priceChange()
    this.$watch('$store.state.activeFormValid', () => {
      const animationElement = this.$refs['succeedAnimation']
      if (this.$store.state.activeFormValid) this.isEditing = true
      if (this.$store.state.activeFormValid || this.isNewDocument() || this.noDocumentsAdded || this.isEditing) {
        // To avoid error logging when loading sku editor, we do this if statement below
        if (animationElement) {
          animationElement.src = this.animationSrc
          animationElement.style.display = 'none'
        }
      } else animationElement.style.display = 'inline-flex'
    })
  },
  activated() {
    this.validate()
  },
  methods: {
    priceChange() {
      const calculationSetting = this.getBrandSettings()?.calculation
      if (calculationSetting && calculationSetting > 0.0) {
        this.uiSku.price = (calculationSetting * this.uiSku.buyPrice).toFixed(1)
        this.defaultCalculation = parseInt(calculationSetting).toFixed(1)
      } else {
        const calculation = 0.0
        this.defaultCalculation = calculation.toFixed(1)
      }

      if (!this.uiSku.buyPrice || !this.uiSku.price || this.uiSku.buyPrice == 0) {
        const calculation = 0.0
        this.calculation = calculation.toFixed(1)
      }
      this.calculation = (this.uiSku.price / this.uiSku.buyPrice).toFixed(1)
      this.margin = productFunctions.calculateMargin(this.uiSku, this.$store.state.vatHi)
      this.$forceUpdate()
    },
    convertMode(mode) {
      return mode.replace('-', '_')
    },
    getItems(field) {
      return fieldFunctions.getItems(field)
    },
    getBrandSettings() {
      return productFunctions.getBrandSettings(this.uiSku.brand, this.$store.state.brands)
    },
    calculateBuyPriceDiscount() {
      if (this.uiSku.buyPrice > 0) {
        this.enableDiscount = !this.enableDiscount

        if (this.enableDiscount) {
          const brandSettings = this.getBrandSettings()
          const discountSetting = brandSettings?.discount
          let discount = 0
          this.OLDBUYPRICE = this.uiSku.buyPrice
          discount = ((discountSetting / 100) * this.uiSku.buyPrice).toFixed(1)
          this.uiSku.buyPrice = (this.uiSku.buyPrice - discount).toFixed(1)
        } else {
          this.uiSku.buyPrice = this.OLDBUYPRICE
        }
      }

      this.priceChange()
    },
    doneAddingSkus() {
      this.selectedSizeRange = ''
      this.uiSku = productFunctions.newSku()
      this.localMode = null
      this.$refs.form.resetValidation()
      this.$emit('edit-sku-action', { done: true })
    },
    cancelForm() {
      this.$refs.form.resetValidation()
      this.$emit('edit-sku-action', { done: true })
    },
    disabledMasterdataFields(field) {
      if (!this.masterdata) return false
      const sku = this.uiSku
      if (!this.fieldsToCheck.find((fieldToCheck) => sku[fieldToCheck])) return false
      return !sku[field]
    },
    async validateBarcode(e) {
      if (e.path && !e.path.map((n) => n.id).includes('barcodeField')) return

      const barcode = this.uiSku.barcode
      if (!barcode) return
      this.barcodeErrorMessage = ''
      if (this.updatedSkus[barcode]) {
        this.barcodeErrorMessage = swT('you_just_added_this_barcode')
        this.uiSku.barcode = ''
        return
      }
      if (globalStore.getLatestCollectionObject('sku')[barcode]) {
        this.barcodeErrorMessage = swT('barcode_exists')
        this.uiSku.barcode = ''
        return
      }
      if (barcode?.length != 12 && barcode?.length != 13) return
      this.barcodeLoading = true
      this.$nextTick(async function() {
        try {
          const res = await webServices.getSku(this.$store.state, barcode, true)
          this.uiSku = deepCopy(res.data)
          this.uiSku.id = this.uiSku.barcode
          this.uiSku.colorFamily = this.uiSku.colorSupplier
          this.barcodeLoading = false
        } catch {
          if (!this.masterdata)
            if (this.uiSku.articleCode) this.$refs['sizeField'].focus()
            else this.$refs['sizeRange'].focus()
          this.barcodeLoading = false
        }
      })
    },
    addMasterData() {
      this.validate()
      if (!this.$store.state.activeFormValid) return
      this.barcodeErrorMessage = ''
      const uiSku = this.uiSku
      uiSku.brand = 'masterdata'
      uiSku.articleCode = 'masterdata'
      uiSku.articleDescription = 'masterdata'
      const field = this.fieldsToCheck.find((key) => uiSku[key] && uiSku[key] !== 'masterdata')
      const value = uiSku[field]
      if (Object.values(this.updatedSkus).find((sku) => sku.brand == 'masterdata' && sku[field] == value)) {
        this.$store.dispatch('raiseAlert', {
          header: 'youJustAddedThisData',
          type: 'error',
          timeout: 3000,
        })
        return
      }
      if (globalStore.getLatestCollectionArray('sku').find((sku) => sku.brand == 'masterdata' && sku[field] == value)) {
        this.$store.dispatch('raiseAlert', {
          header: 'alreadyExists',
          type: 'error',
          timeout: 3000,
        })
        return
      }
      this.uiSku.barcode = uuidv4()
      this.$emit('edit-sku-action', {
        addSku: { ...this.uiSku, size: [uiSku.size], id: '' + this.uiSku.barcode, mainSize: this.uiSku.size, active: true },
        isGeneratingBarcodes: this.isGeneratingBarcodes,
      })
      this.uiSku.barcode = ''
    },
    addSku() {
      this.validate()
      // could be null for UI reasons, normalize to empty string
      this.uiSku.size = this.uiSku.size || ''
      if (!this.$store.state.activeFormValid) return
      this.barcodeErrorMessage = ''
      const sizesToCheck = this.isGeneratingBarcodes ? this.uiSku.size : [this.uiSku.size]
      for (const size of sizesToCheck) {
        if (
          Object.values(this.updatedSkus).find(
            (sku) => this.uiSku.articleCodeSupplier == sku.articleCodeSupplier && size == sku.size && this.uiSku.colorFamily == sku.colorDescription
          )
        ) {
          this.$store.dispatch('raiseAlert', {
            header: 'productColorSizeExists',
            type: 'error',
            timeout: 3000,
          })
          return
        }
      }

      const rawSku = deepCopy(this.uiSku)
      this.$emit('edit-sku-action', {
        addSku: {
          ...rawSku,
          active: true,
          articleCode: rawSku.articleCodeSupplier,
          articleGroupSupplier: rawSku.articleGroup,
          collectionSupplier: rawSku.collection,
          colorCode: rawSku.colorCodeSupplier,
          colorDescription: rawSku.colorFamily,
          id: '' + rawSku.barcode,
          size: this.isGeneratingBarcodes && Array.isArray(rawSku.size) ? rawSku.size : [rawSku.size],
        },
        isGeneratingBarcodes: this.isGeneratingBarcodes,
      })
      if (this.isGeneratingBarcodes) this.uiSku.colorFamily = ''
      else this.uiSku.barcode = ''

      eventBus.$emit('newSkuCount')
      this.$refs['barcodeField'].focus()
      this.$refs['barcodeField'].$el.querySelector('input').select()
      this.noDocumentsAdded = false
      this.isEditing = false
    },
    updateSku() {
      this.validate()
      // if (!this.$store.state.activeFormValid) return
      const updatedSku = deepCopy(this.uiSku)
      if (Array.isArray(updatedSku.size)) updatedSku.size = updatedSku.size[0]
      this.$emit('edit-sku-action', { updateSku: updatedSku })
    },
    required(value) {
      if (this.masterdata) return true
      if (value instanceof Array && value?.length == 0) return 'Required.'
      return !!value || 'Required.'
    },
    validate() {
      this.$store.state.activeFormValid = this.$refs.form?.validate()
    },
    skuImages(sku) {
      if (!sku.images) return
      return this.sku.images.split(',')
    },
    imageSrc(imageUrl, imageWidth) {
      return tools.imageUrlParse(imageUrl, imageWidth)
    },
    updateFieldLevelFind(event) {
      // Extract the field name from data-set attribute
      const key = event.target.attributes['data-test'].value.replace('Field', '')
      // The filter field of articleCode called articlecode in swSkuEditor.vue
      // but here in uiSku object it has been assigned to the key name articleCodeSupplier as v-model
      // Therefor we send to swSkuEditor.vue the name articlecode

      eventBus.$emit('updateFieldLevelFind', { key: key.toLowerCase(), value: this.uiSku[key] })
    },
    updateSkuSize() {
      if (!this.selectedSizeRange) return
      if (this.isGeneratingBarcodes) this.uiSku.size = this.selectedSizeRange.split(',')
      else this.uiSku.size = this.selectedSizeRange.split(',')[0]
    },
    isNewDocument() {
      return !Object.keys(this.uiSku).some((key) => {
        return key === 'brand' ? false : this.uiSku[key]
      })
    },
    handleSizeRangeChange(value) {
      if (!value) this.selectedSizeRange = ''
    },
  },
}
</script>

<style scoped>
.right-input >>> input {
  text-align: right;
}
.sticky-element {
  top: 0;
  position: sticky !important;
  z-index: 4;
  border-bottom: 1px solid #0003;
  border-radius: 0;
}
.discount_button {
  top: 20%;
}
.succeed-animation {
  display: none;
  position: relative;
  width: 130px;
  margin-top: -100px;
  margin-right: -36px;
  top: 45px;
}
</style>
