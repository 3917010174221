<template>
  <div>
    <div v-if="showHeroImages.length > 0">
      <div v-for="(brand, i) in showHeroImages" :key="i">
        <h2 class="text-xl-h4 py-2" primary-title>{{ brand.brandName }}</h2>
        <v-img :src="brand.src" :data-test="`${brand.brandId}`" height="40vh" position="center top" @click="clickHero(brand)"></v-img>
      </div>
    </div>
    <swBrandGridGroup v-if="showHeroImages.length == 0 && filter" :brands="filteredBrands" @select="selectBrand" />

    <div v-if="showHeroImages.length == 0 && !filter">
      <!-- Grouped view -->
      <swBrandGridGroup :brands="favBrands" :title="swT('favorites')" @select="selectBrand" />
      <swBrandGridGroup :brands="sliceBrandsRow(popularBrands)" :title="swT('popular')" @select="selectBrand" />
      <swBrandGridGroup :brands="sliceBrandsRow(newBrands)" :title="swT('newbrands')" @select="selectBrand" />
      <swBrandGridGroup :brands="sliceBrandsRow(updatedBrands)" :title="swT('updated')" @select="selectBrand" />
      <swBrandGridGroup :brands="trendingBrands" :title="swT('trending')" @select="selectBrand" />
      <swBrandGridGroup :brands="yourBrands" :title="currentTenant.name" @select="selectBrand" />
    </div>
    <swConfigureBrandPopup v-if="configureBrandPopup" :brand="selectedBrand" @operation="configureBrandPopupOperation" @close-configure-brand-popup="configureBrandPopup = false" />
    <swUpdatedBrandPopup v-if="updatedBrandPopup" :brand="selectedBrand" @operation="updatedBrandPopupOperation" @close-updated-brand-popup="updatedBrandPopup = false" />
  </div>
</template>

<script>
import { swT } from '@/functions/i18n'
import animations from '../functions/animations'
import swUpdatedBrandPopup from '../components/swUpdatedBrandPopup.vue'
import swConfigureBrandPopup from '../components/swConfigureBrandPopup.vue'
import swBrandGridGroup from '../components/swBrandGridGroup.vue'
import globalStore from '../store/globalStore'
import userFunctions from '../functions/users'
import { buildPropertyMappingFn, deepCopy, hashBrand } from '@softwear/latestcollectioncore'
import { showDialog } from '../functions/dialogService'
import { getSession } from '../functions/getSession'

export default {
  components: {
    swBrandGridGroup,
    swConfigureBrandPopup,
    swUpdatedBrandPopup,
  },
  props: ['filter', 'bpopup'],
  data() {
    return {
      swT,
      configureBrandPopup: false,
      updatedBrandPopup: false,
      selectedBrand: null,
    }
  },
  computed: {
    showHeroImages() {
      if (userFunctions.hasRole(this.$store.getters.roles, 'lc-b2b')) return this.heroImageList(getSession().b2bAccessList)
      if (this.$store.state.activeConfig.products?.brandOwnerView?.value && this.$store.state.brandOwnerOf.length > 0) return this.heroImageList(this.$store.state.brandOwnerOf)
      return []
    },

    columnsNumber() {
      // These numbers have to match the layout so that
      // <swBrandGridGroup /> displays exactly one row
      if (this.$vuetify.breakpoint.xs) return 2
      if (this.$vuetify.breakpoint.sm) return 3
      if (this.$vuetify.breakpoint.md) return 4
      if (this.$vuetify.breakpoint.lg) return 12
      if (this.$vuetify.breakpoint.xl) return 12
      return 4
    },
    currentTenant() {
      return getSession().tenants.find((tenant) => tenant.id == getSession().current_tenant)
    },
    yourBrands() {
      return this.$store.state.brands.filter((b) => b.usedByTenant).slice(0, 100)
    },
    filteredBrands() {
      const hashedBrandPrefix = hashBrand(this.filter.toLowerCase())
      return this.$store.state.brands.filter((b) => b.collection?.includes(hashedBrandPrefix) || b.aliases?.find((a) => a.includes(hashedBrandPrefix))).slice(0, 100)
    },
    popularBrands() {
      const popularBrands = this.$store.state.metadata['brands'].popularBrands
      return this.$store.state.brands.filter((b) => popularBrands.includes(b.collection)).slice(0, this.columnsNumber)
    },
    trendingBrands() {
      const trendingBrands = this.$store.state.metadata['brands'].trendingBrands
      return this.$store.state.brands.filter((b) => trendingBrands.includes(b.collection)).slice(0, this.columnsNumber)
    },
    newBrands() {
      const newBrands = this.$store.state.metadata['brands'].newBrands
      return this.$store.state.brands.filter((b) => newBrands.includes(b.collection)).slice(0, this.columnsNumber)
    },
    favBrands() {
      const favBrands = this.$store.state.activeConfig.favorites.favoriteBrands
      return this.$store.state.brands.filter((b) => b.name && favBrands.value.includes(b.collection))
    },
    updatedBrands() {
      return deepCopy(this.$store.state.brands.filter((b) => b.source == 'latestCollection' && b.url))
        .sort((a, b) => {
          return b.lasttime - a.lasttime
        })
        .slice(0, 20)
    },
  },
  created() {
    this.showConfigurePopUp()
  },
  updated() {
    animations.cardStagger()
  },
  activated() {
    this.showConfigurePopUp()
    animations.cardStagger()
  },
  methods: {
    heroImageList(brandList) {
      const brandsObject = globalStore.getLatestCollectionObject('metabrandsetting')
      return brandList.map((brandId) => {
        const brand = brandsObject[brandId]
        return {
          src: brand.heroImageUrl,
          brandId: brandId,
          brandName: brand.name,
          collection: brand.collection,
        }
      })
    },
    clickHero(brand) {
      if (this.$store.state.activeConfig.products?.brandOwnerView?.value) this.selectBrand(brand)
      else this.$router.push({ path: 'products', query: { brandId: brand.brandId } })
    },
    sliceBrandsRow(brandGroup) {
      return brandGroup.slice(0, this.columnsNumber)
    },
    async configureBrandPopupOperation(options) {
      this.configureBrandPopup = false
      if (options.save) {
        this.selectedBrand.propertyMapping = this.selectedBrand.propertyMapping.filter((e) => e.to != '')
        const brandSetting = deepCopy(this.selectedBrand)
        if (userFunctions.hasRole(this.$store.getters.roles, 'sw')) {
          await this.$store.dispatch('updateObjects', {
            api: globalStore.getLatestCollectionAPI('metabrandsetting'),
            data: [brandSetting],
          })
        } else if (userFunctions.hasRole(this.$store.getters.roles, 'products-dataprovider_admin')) {
          brandSetting.id = brandSetting.id + ':' + brandSetting.dataProvider
          await this.$store.dispatch('updateObjects', {
            api: globalStore.getLatestCollectionAPI('dataproviderbrandsetting'),
            data: [brandSetting],
          })
        } else {
          await this.$store.dispatch('updateObjects', {
            api: globalStore.getLatestCollectionAPI('tenantbrandsetting'),
            data: [brandSetting],
          })
          const skus = globalStore.getLatestCollectionArray('sku')
          if (this.$store.state.activeConfig.products.applyTenantMapping.value) {
            const mapper = buildPropertyMappingFn(globalStore.getLatestCollectionArray('tenantbrandsetting'))
            skus.forEach(mapper)
          }
        }
        // globalStore.getLatestCollectionAPI('sku')?.pull()
        this.selectedBrand = null
      }
      if (options.cancel) {
        this.selectedBrand = null
      }
    },
    async updateBrandGroupInMetaData(selectedBrand, brandGroupName) {
      const metadata = deepCopy(this.$store.state.metadata['brands'])
      const brandGroup = metadata[brandGroupName]
      const index = brandGroup.indexOf(selectedBrand.collection)
      if (index >= 0) brandGroup.splice(index, 1)
      else brandGroup.push(selectedBrand.collection)
      await this.$store.dispatch('updateObjects', {
        api: globalStore.getLatestCollectionAPI('metadata'),
        data: [metadata],
      })
    },
    async brandPopupOperation(options) {
      if (options.configure) this.configureBrandPopup = true
      if (options.updated) this.updatedBrandPopup = true
      if (options.showProducts)
        this.$router.push({
          path: 'products',
          query: { brandId: this.selectedBrand.collection },
        })
      if (options.showRestrictedProducts) {
        if (userFunctions.hasDataProviderSubscription(options.showRestrictedProducts, this.$store.state))
          return this.$router.push({
            path: 'products',
            query: {
              brandId: this.selectedBrand.collection,
              dataProvider: options.showRestrictedProducts,
            },
          })
        this.$router.push({
          path: 'subscribe',
          query: { dataProvider: options.showRestrictedProducts, type: options.type, brand: options.brand },
        })
      }
      if (options.articleStatus)
        this.$router.push({
          path: 'reports',
          query: {
            type: 'articlestatus',
            groups: `sku.articleGroup`,
            period: `LASTMONTH`,
            brandFilter: this.selectedBrand.name,
            reportViz: 'table',
          },
        })

      if (options.stock)
        this.$router.push({
          path: 'reports',
          query: {
            brandFilter: this.selectedBrand.name,
            groups: `sku.brand`,
            fields: `43`,
            period: `TODAY`,
            reportViz: 'matrix',
          },
        })

      if (options.toggleFavorite) {
        const userPreference = deepCopy(this.$store.state.activeConfig)
        const favBrands = userPreference.favorites.favoriteBrands.value
        const index = favBrands.indexOf(this.selectedBrand.collection)
        if (index >= 0) favBrands.splice(index, 1)
        else favBrands.push(this.selectedBrand.collection)
        userPreference.favorites.favoriteBrands.value = favBrands
        await this.$store.dispatch('updateObjects', {
          api: globalStore.getLatestCollectionAPI('userpreference'),
          data: [{ id: getSession().settings.user.id, ...userPreference }],
        })
        this.$store.dispatch('loadSettings')
      }
      if (options.toggleNew) await this.updateBrandGroupInMetaData(this.selectedBrand, 'newBrands')
      if (options.togglePopular) await this.updateBrandGroupInMetaData(this.selectedBrand, 'popularBrands')
      if (options.toggleTrending) await this.updateBrandGroupInMetaData(this.selectedBrand, 'trendingBrands')
      if (options.navigateTo) this.$router.push(options.navigateTo)
    },
    async updatedBrandPopupOperation(options) {
      this.updatedBrandPopup = false
      const collection = this.selectedBrand.collection
      if (options.showProducts) this.$router.push({ path: 'skueditor', query: { brand: collection } })

      if (options.cancel) {
        this.showBrandPopup()
      }
      if (options.noSync) {
        this.showBrandPopup()
        this.selectedBrand.lastSync = new Date().getTime()
        await this.$store.dispatch('updateObjects', {
          api: globalStore.getLatestCollectionAPI('tenantbrandsetting'),
          data: [this.selectedBrand],
        })
        // The brands array in the store does not react to DB updates so we need to update it here to reflect the changes in the UI
        const brand = this.$store.state.brands.find((b) => b.collection == collection)
        brand.lastSync = this.selectedBrand.lastSync
        this.$forceUpdate()
      }
    },
    selectBrand(brand) {
      this.selectedBrand = deepCopy(brand)
      this.showBrandPopup()
    },
    saveBrand(newBrandName) {
      const newBrand = {
        name: newBrandName,
        id: newBrandName,
        collection: newBrandName,
        source: 'tenant',
        usedByTenant: true,
      }
      this.$store.state.brands.push(newBrand)
      this.selectedBrand = newBrand
      this.showBrandPopup()
    },
    showConfigurePopUp() {
      if (this.bpopup) {
        this.configureBrandPopup = true
        const brand = this.$store.state.brands.find((brand) => brand.name == this.bpopup)
        this.selectedBrand = brand
      }
    },
    async showBrandPopup() {
      const result = await showDialog('swBrandPopup', { brand: this.selectedBrand })
      if (result && !('cancel' in result)) this.brandPopupOperation(result)
    },
  },
}
</script>
